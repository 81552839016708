import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import {themeBright} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import ThemeBox from "../../components/bricks/ThemeBox";

const Features = ({location}) => (
  <DefaultLayout
    title="Due Dates"
    description="Due Dates allow you to associate dates to specific tasks"
    location={location}
  >
    <Hero size="sm" title="Due Dates" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription img="due-dates/due-dates-create.png">
        Setting Due dates to a card allows you to be reminded of important upcoming events. They are
        a natural addition to milestones by allowing you to signal one-off dates.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="All your due tasks in a single view"
        img="due-dates/due-dates-hand.png"
        imgRight
      >
        The hand serves as a dashboard for your personal view on what's going on in the project. As
        such it offers a convenient overview highlighting all cards that need attention soon.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="We make sure you won't forget important dates anymore"
        img="due-dates/due-dates-notifications.png"
      >
        Our notification system ensures that you will remember to deal with due tasks. It will keep
        nudging you until you've either completed the underlying task or moved the due date.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
